import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    home: "Home",
    aboutus: "About us",
    services: "What we offer",
    news: "News",
    references: "References",
    imprint: "Imprint",
    contact: "Contact",
    privacy: "Privacy",
  },
  de: {
    home: "Startseite",
    aboutus: "Über uns",
    services: "Leistungen",
    news: "News",
    references: "Referenzen",
    imprint: "Impressum",
    contact: "Kontakt",
    privacy: "Datenschutz",
  },
};

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
  messages, // set locale messages
});

export default i18n;
