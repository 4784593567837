import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cmsUrl: process.env.VUE_APP_API_URL,
    token: process.env.VUE_APP_TOKEN,
    screenWidth: null,
    screenBreakXL: 1200,
    screenBreakLG: 992,
    activeMenuPoint: null,
    scrollPosition: 0,
    navOpen: false,
    curLang: "de",
    news: [],
    projects: [],
    pages: [
      {
        ID: 1,
        name: "home",
        pageTitle: "Terra Nova Industries",
        pageImage: "",
        pageDescription: "Terra Nova Industries",
        pageKeywords: "Terra, Nova, Industrie",
        footerImage: "",
        footerQuote:
          "There is a place, like no place on earth. A land full of wonder, mystery, and danger. Some say, to survive it, you need to be as mad as a hatter. Which, luckily, I am.",
        footerQuoteAuthor: "Luis Carrol",
        footerPosX: "",
        footerPosY: "",
      },
      {
        ID: 2,
        name: "about",
        pageTitle: "About | Terra Nova Industries",
        pageImage: "",
        pageDescription: "Terra Nova Industries",
        pageKeywords: "Terra, Nova, Industrie",
        footerImage: require("@/assets/images/spacesplash.png"),
        footerQuote:
          "Never discourage anyone who continually makes progress. No matter how slow.",
        footerQuoteAuthor: "Plato",
        footerPosX: "",
        footerPosY: "bottom",
      },
      {
        ID: 3,
        name: "reference",
        pageTitle: "References | Terra Nova Industries",
        pageImage: "",
        pageDescription: "Terra Nova Industries",
        pageKeywords: "Terra, Nova, Industrie",
        footerImage: require("@/assets/images/spacesplash.png"),
        footerQuote: "A rising tide lifts all boats.",
        footerQuoteAuthor: "JFK",
        footerPosX: "",
        footerPosY: "top",
      },
      {
        ID: 4,
        name: "news",
        pageTitle: "News | Terra Nova Industries",
        pageImage: "",
        pageDescription: "Terra Nova Industries",
        pageKeywords: "Terra, Nova, Industrie",
        footerImage: require("@/assets/images/spacesplash.png"),
        footerQuote: "",
        footerQuoteAuthor: "",
        footerPosX: "",
        footerPosY: "center",
      },
    ],
  },
  getters: {},
  mutations: {
    SET_DATA(state, { data, stateName }) {
      state[stateName] = data.data;
    },
    updateScreenWidth(state, width) {
      state.screenWidth = width;
    },
    updateActiveMenuPoint(state, ID) {
      state.activeMenuPoint = ID;
    },
    updateNavOpen(state, status) {
      state.navOpen = status;
    },
    setCurLang(state, lang) {
      state.curLang = lang;
      i18n.locale = lang;
      localStorage.setItem("locale", lang);
    },
    SET_SCROLL_POSITION(state, position) {
      state.scrollPosition = position;
    },
  },
  actions: {
    setScreenWidth({ commit }) {
      let width = window.innerWidth;
      commit("updateScreenWidth", width);
    },
    async fetchDataFromApi({ commit }, { apiUrl, stateName }) {
      try {
        const response = await apiClient.get(apiUrl);
        commit("SET_DATA", { data: response.data, stateName });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    initializeLanguage({ commit }) {
      const browserLang = navigator.language.split("-")[0];
      const storedLang = localStorage.getItem("locale");
      const defaultLang =
        browserLang === "de" || browserLang === "en" ? browserLang : "de";

      const langToSet = storedLang || defaultLang;
      commit("setCurLang", langToSet);

      if (!storedLang) {
        localStorage.setItem("locale", langToSet);
      }
    },
  },
  modules: {},
});
