import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    beforeEnter: (to, from, next) => {
      // Sanft nach oben scrollen und warten, bevor die Route wechselt
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => next(), 500); // 500 ms Verzögerung
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    alias: "/ueberuns",
    beforeEnter: (to, from, next) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => next(), 500);
    },
  },
  {
    path: "/references",
    name: "reference",
    component: () => import("../views/ReferenceView.vue"),
    alias: "/referenzen",
    beforeEnter: (to, from, next) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => next(), 500);
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/NewsView.vue"),
    beforeEnter: (to, from, next) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => next(), 500);
    },
  },
  {
    // Catch-all Route für alle anderen Pfade
    path: "*",
    redirect: "/", // Umleitung auf Startseite
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Wenn eine gespeicherte Position vorhanden ist, sanft dorthin scrollen
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo({
            top: savedPosition.y,
            left: savedPosition.x,
            behavior: "smooth",
          });
          resolve(savedPosition);
        }, 300); // kurze Verzögerung, um das sanfte Scrollen besser zu zeigen
      });
    } else if (to.hash) {
      // Wenn ein Anchor-Link vorhanden ist, sanft zum Element scrollen
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      // Standardmäßig zur oberen Position der Seite sanft scrollen
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          resolve({ x: 0, y: 0 });
        }, 300);
      });
    }
  },
});

router.beforeEach((to, from, next) => {
  // Close MobileNav
  store.commit("updateNavOpen", false);
  next();
});

export default router;
