<template>
  <div id="app">
    <div class="newsNav">
      <router-link to="/news">{{ $t("news") }}</router-link>
    </div>
    <HeaderPart />

    <main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>

    <FooterPart />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    HeaderPart: () => import("@/TemplateParts/HeaderPart.vue"),
    FooterPart: () => import("@/TemplateParts/FooterPart.vue"),
  },
  computed: {
    ...mapState(["pages"]),
    page: {
      get() {
        return this.pages.find((page) => page.name === this.$route.name) || {};
      },
      deep: true,
    },
  },
  metaInfo() {
    const { pageTitle, pageImage, pageKeywords, pageDescription } =
      this.page || {};
    const seoAlt = {
      title: "Terra Nova Industries",
      description: "Webseite von Terra Nova Industries",
      keywords: "Terra, Nova, Industries",
      image: "https://dummyimage.com/300/09f/fff.png",
      locale: "de",
    };
    return {
      title: pageTitle || seoAlt.title,
      meta: [
        {
          name: "description",
          content: pageDescription || seoAlt.description,
        },
        { name: "robots", content: "index, follow" },
        {
          name: "keywords",
          content: pageKeywords || seoAlt.keywords,
        },
        {
          property: "og:title",
          content: pageTitle || seoAlt.title,
        },
        {
          property: "og:description",
          content: pageDescription || seoAlt.description,
        },
        { property: "og:image", content: pageImage || seoAlt.image },
        {
          property: "og:locale",
          content: seoAlt.locale,
        },
      ],
    };
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/main.sass'

.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s ease

.fade-enter, .fade-leave-to /* .fade-leave-active für < 2.1.8 */
  opacity: 0

.newsNav
  position: fixed
  right: 0
  top: 15vh
  background-color: $gold
  padding: 1rem .5rem
  z-index: 5
  transition: .6s
  &:hover
    transform: scale(1.1)

  a
    writing-mode: vertical-rl
    transform: rotate(180deg)
    text-align: center
    color: white
    margin: 0
    padding: 0 0 .5rem 0
    text-transform: uppercase
    &:hover
      color: white
      letter-spacing: .05
</style>
